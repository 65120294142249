
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        


























.why-voo,
[class*='why-voo--'] {
  position: relative;
  flex-basis: 100%;
  width: 100%;
  margin-bottom: $spacing * 3;

  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 100vw;
    height: 100%;
  }

  &.is-light-gray {
    &::before {
      background-color: $c-light-gray;
    }
  }

  @include mq('l') {
    flex-basis: 60%;
    width: 60%;
    padding-top: 10rem;
    padding-right: $spacing * 3;
    padding-bottom: 10rem;
    margin-bottom: 0;
  }

  @include mq('xl') {
    flex-basis: 50%;
    width: 50%;
    padding-right: $spacing * 4;
  }

  @include mq('xxl') {
    padding-right: $spacing * 5;
  }
}

.why-voo-inner {
  position: relative;
}

.why-voo__links__title {
  position: relative;
  margin-top: 0;
  margin-bottom: $spacing * 2.5;
}

::v-deep .section-cta-outer {
  margin-bottom: 0;
  padding-bottom: 0;
}

::v-deep .section-cta {
  background-color: $c-light;
}

::v-deep .section-cta__content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @include mq('s') {
    align-items: center;
  }
}

::v-deep .section-row__content__subtitle {
  display: none;
}

::v-deep .section-cta__content__icon {
  flex-shrink: 0;
}
